<template>
  <v-container class="choose-tenant-dialog">
    <v-row>
      <v-col cols="12">
        <v-list>
          <v-list-item-group v-model="selectedCompany" color="primary">
            <v-list-item
              v-for="(company, index) in getDialog.data.companies"
              :key="index"
              two-line
              :value="company"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ company.public_company_name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ company.public_company_address }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon
                  v-if="
                    selectedCompany && selectedCompany.name === company.name
                  "
                  v-text="'$check'"
                />
              </v-list-item-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import dialogTypes from "@/components/Dialogs/dialogTypes";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "ChooseTenantDialog",
  mixins: [sharedActions],
  data() {
    return {
      selectedCompany: null,
    };
  },
  watch: {
    selectedCompany() {
      this.handleLogin();
    },
  },
  methods: {
    ...mapActions({
      setTenantData: "setup/setTenantData",
    }),
    handleLogin() {
      this.setTenantData(this.selectedCompany);
      if (this.getDialog.data && this.getDialog.data.type === "login") {
        this.$emit("close", { login: true });
      } else if (
        this.getDialog.data &&
        this.getDialog.data.type === "forgottenPassword"
      ) {
        this.openDialog({
          type: dialogTypes.FORGOTTEN_PASSWORD,
          title: this.$trans("forgotten_password_title"),
          data: {
            selectedCompany: this.selectedCompany,
            email: this.getDialog.data.email,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item {
  height: 72px;
}
</style>
